import React from "react"
import {
  ContactForm,
  Layout,
  SyllabusBanner,
  SyllabusHeader,
  HelmetWrapper,
  SyllabusTexts,
} from "../components"
import SyllabusSlider from "../components/syllabus/syllabus-slider"
import { SYLLABUS_SLIDER } from "../model/syllabus-slider-data"
import OgImage from "../images/syllabus-og-images/6.png"
import Vasek from "../images/photos/vasek.jpeg"

const LECTURER = [
  {
    src: Vasek,
    alt: "",
    personName: "Vašek Jelen",
    text: [
      "Vašek se více než 10 let věnuje digitální analytice – od nastavování měření a vizualizace po interpretaci marketingových dat. Skrze klientské projekty a školení pomáhá firmám a mediálním agenturám mít v datech pořádek a umět je naplno využít. Jeho tech stack zahrnuje jak GA 360/GA4 360, tak Adobe Analytics a další související nástroje.",
      "Po letech na volné noze spoluzaložil analytické studio MeasureDesign, které kromě analytických projektů pro firmy a mediální agentury vzdělává nové analytiky a analytičky.",
    ],
    linkedInProfile: "//linkedin.com/in/vaclavjelen",
  },
]
const PrivacyASber: React.FC = () => {
  return (
    <Layout>
      <HelmetWrapper title="Privacy a sběr 1st party dat" ogImage={OgImage} />

      <SyllabusHeader
        orangeText="Privacy a sběr"
        blackText="1st party dat"
        numberOfLesson="6/13"
      ></SyllabusHeader>

      <SyllabusBanner data={LECTURER} />

      <SyllabusTexts>
        <div>
          privacy a consent
          <div>stručný legislativní rámec</div>
          <div>
            možnosti, jak řešit consent management – 3rd party tool, vlastní
            řešení – výhody / nevýhody, ukázky
          </div>
          <div>
            možnosti, jak consent držet (browser storages, expirace cookies,
            reset cookie přes sGTM, apod.)
          </div>
          <div>nasazení obou možností a nastavení GTM</div>
        </div>

        <div>
          anonymní měření (nezávislé na cookie liště)
          <div>jaké jsou možnosti legálně anonymně měřit</div>
          <div>v čem GTM consent mode nesplňuje GDPR</div>
          <div>architektura anonymního trackingu (IP proxy, zapojení sGTM)</div>
          <div>nasazení anonymního trackingu</div>
          <div>dashboard pro anonymní dataset vs. full dataset</div>
        </div>

        <div>
          sběr 1st party dat do dalších systémů mimo GA
          <div>datalayer jako univerzální zdroj dat pro všechny systémy</div>
          <div>
            nasazování non GA4 věcí a align s consent managementem
            <div>ID management anonymních / neanonymních návštěvníků</div>
            <div>nasazení CDP / CRM skriptů, vazba na ID management </div>
            <div>
              média (Ads, Sklik, Adform, FB) – browser vs. server side
              implementace, tvorba a sběr základních / advanced publik
            </div>
            <div>
              blending GA4 dat a mediálních dat (import do GA4 / import do BQ) –
              proč, v jakých situacích a jak
            </div>
          </div>
        </div>
      </SyllabusTexts>

      <SyllabusSlider data={SYLLABUS_SLIDER} />

      <ContactForm
        heading="Máš otázku?"
        subheading="Napiš si o odpověď Adamovi"
      />
    </Layout>
  )
}

export default PrivacyASber
